import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { Main } from "../components/sections/Content"
import { DivDefaultWrapper, Subtitle } from "../components/parts/General"
import { Title } from "../components/parts/General"
import Seo from "../components/Seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Main>
        <DivDefaultWrapper as="section">
          <Title>Page not found</Title>
          <Subtitle>
            Sorry, but we couldn't find the page you're looking for :(
          </Subtitle>
          <Link to="/">Go home</Link>
        </DivDefaultWrapper>
      </Main>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <Seo title="Not found" />
